import { red } from '@mui/material/colors'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: '#4694d1',
            },
            secondary: {
                main: '#fff',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#fff',
            },
        },
        typography: {
            button: {
                // ボタンのラベルが大文字になるのを防ぐ
                textTransform: 'none',
            },
            // see: https://mui.com/customization/default-theme/
            h1: {
                fontSize: '2rem',
            },
            h2: {
                fontSize: '1.5rem',
            },
            h3: {
                fontSize: '1.25rem',
            },
            h4: {
                fontSize: '1rem',
            },
            h5: {
                fontSize: '0.75rem',
            },
            h6: {
                fontSize: '0.5rem',
            },
        },
        components: {
            MuiFormControl: {
                defaultProps: {
                    margin: 'normal',
                    variant: 'standard',
                },
            },
            MuiFormLabel: {
                defaultProps: {
                    sx: {
                        // 上位部分の余白を空けて、 FormLabel と その上部のコンポーネントの違いをわかりやすくする。
                        pt: 1,
                    },
                },
            },
        },
    })
)

export default theme
