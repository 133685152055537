import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import React from 'react'
import { RecoilRoot } from 'recoil'
import theme from '../apps/commons/components/_theme'
import type { AppProps } from 'next/app'
import '../styles/globals.css'

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    return (
        <RecoilRoot>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no, viewport-fit=cover"
                />
                <title>field-polygon</title>
                {/*<link rel="manifest" href="/manifest.json" />*/}
                {/*<link href="/icons/icon-16x16.png" rel="icon" type="image/png" sizes="16x16" />*/}
                {/*<link href="/icons/icon-32x32.png" rel="icon" type="image/png" sizes="32x32" />*/}
                {/*<link rel="apple-touch-icon" href="/apple-icon.png"></link>*/}
                {/*<meta name="theme-color" content="#317EFB" />*/}
                {/*<link*/}
                {/*    rel="stylesheet"*/}
                {/*    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"*/}
                {/*/>*/}
            </Head>
            <SessionProvider session={session}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Component {...pageProps} />
                </ThemeProvider>
            </SessionProvider>
        </RecoilRoot>
    )
}
